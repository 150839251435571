import { Link as MuiLink, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { AccountCircle, Assignment, FolderOpen, Memory } from '@mui/icons-material'
import { type ActionFunctionArgs, Link, type LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import { OrderStatus, Pagination, Search } from '@trinity/components'
import { type UseLoaderData, formatDate, getOrders, getSearches, viewOrderUrl } from '@trinity/utils'

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url)
  const scope = url.searchParams.get('scope') ?? 'all'
  const page = url.searchParams.get('page') ?? 1
  const perPage = url.searchParams.get('perPage') ?? 100
  const orders = await getOrders({ scope, page: Number(page), per_page: Number(perPage) })

  return orders
}

export async function action({ request }: ActionFunctionArgs) {
  const data = Object.fromEntries(await request.formData())

  if (data.search) {
    const suggestions = await getSearches({ q: String(data.search) })
    return { suggestions }
  }

  return null
}

export function Orders() {
  const loaderData = useLoaderData() as UseLoaderData<typeof loader>

  return (
    <Stack alignItems='center' spacing={8} pt={6}>
      <Search<TrinityAPI.SearchType> isHome name='search'>
        {({ suggestion, searchTerm }) => <AutocompleteSuggestion suggestion={suggestion} value={searchTerm} />}
      </Search>
      <OrderStatus>
        <OrderStatus.Tabs />
        <OrderStatus.Filters />
        {loaderData.orders.length === 0 && <OrderStatus.NoOrdersFound />}
        <Stack spacing={3} width={1}>
          {loaderData.orders.map(order => (
            <OrderStatus.Accordion key={order.id} order={order}>
              <Stack direction='row' justifyContent='space-between' width={1}>
                <Typography noWrap variant='h6' sx={{ maxWidth: 180 }}>
                  {order.customer.name}
                </Typography>
                {order.orderedAt && <Typography variant='body1'>{formatDate(order.orderedAt)}</Typography>}
              </Stack>
            </OrderStatus.Accordion>
          ))}
        </Stack>
      </OrderStatus>
      <Pagination total={loaderData.totalOrders} pageSizeOptions={[100, 200, 300]} />
    </Stack>
  )
}

interface AutocompleteSuggestionProps {
  suggestion: TrinityAPI.SearchType
  value: string
}

function AutocompleteSuggestion({ suggestion, value }: AutocompleteSuggestionProps) {
  const LinkComponent = ['order', 'garment'].includes(suggestion.type) ? MuiLink : Link
  const url = getUrl(suggestion)

  return (
    <ListItem disablePadding key={suggestion.id}>
      <ListItemButton component={LinkComponent} href={url} to={url}>
        <ListItemIcon>
          <SearchIcon type={suggestion.type} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: 'body1' }}>
          <Highlighter searchWords={[value]} textToHighlight={suggestion.name} highlightTag='strong' />
        </ListItemText>
        {suggestion.description && (
          <ListItemText primaryTypographyProps={{ variant: 'body2', noWrap: true, sx: { maxWidth: 120 } }}>
            <Highlighter searchWords={[value]} textToHighlight={suggestion.description} highlightTag='strong' />
          </ListItemText>
        )}
      </ListItemButton>
    </ListItem>
  )
}

function SearchIcon({ type }: { type: TrinityAPI.SearchType['type'] }) {
  switch (type) {
    case 'fabric':
      return <Memory />
    case 'collection':
      return <FolderOpen />
    case 'customer':
      return <AccountCircle />
    case 'order':
    case 'garment':
      return <Assignment />
  }
}

function getUrl(suggestion: TrinityAPI.SearchType) {
  switch (suggestion.type) {
    case 'fabric':
      return `/fabric-explorer/fabrics/${suggestion.id}`
    case 'collection':
      return `/fabric-explorer/collections/${suggestion.id}`
    case 'customer':
      return `/clients/${suggestion.id}`
    case 'order':
      return viewOrderUrl(suggestion.id)
    case 'garment':
      return viewOrderUrl(suggestion.dealerOrderId ?? 0)
  }
}
